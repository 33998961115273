/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import 'twin.macro';

type CheckmarkProps = {
  label: string;
  name: string;
  required?: boolean;
  className?: string;
};

export const Checkmark: FC<CheckmarkProps> = ({
  label,
  name,
  required,
  className,
}) => {
  return (
    <div tw="form-control">
      <label tw="cursor-pointer label">
        <span tw="label-text mr-4">{label}</span>
        <input
          required={required}
          name={name}
          type="checkbox"
          className={className}
          tw=" checkbox-success checkbox text-green-500 checked:([--chkbg: theme('colors.green.500')])"
        />
      </label>
    </div>
  );
};
