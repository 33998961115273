/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { FC, PropsWithChildren } from 'react';
import tw from 'twin.macro';
import { Text } from '../Text/Text';

export type ChatBubbleProps = {
  isSender?: boolean;
  sender?: string;
  icon?: string | JSX.Element;
  iconIsImage?: boolean;
  iconIsRound?: boolean;
  timeStamp?: string;
};

export const ChatBubble: FC<PropsWithChildren<ChatBubbleProps>> = ({
  isSender = true,
  sender = 'Agent',
  timeStamp = '12:00',
  icon,
  iconIsImage = false,
  iconIsRound = true,
  children,
}) => {
  return (
    <div
      css={[isSender ? tw`ml-auto` : tw`mr-auto`]}
      tw="p-4 bg-slate-800 bg-opacity-50 lg:min-w-[400px] max-w-full backdrop-blur-md rounded-md flex items-center text-white space-x-4"
    >
      {icon && (
        <div tw="w-10">
          {iconIsImage ? (
            <img
              css={[
                iconIsRound
                  ? tw`rounded-full object-cover`
                  : tw`rounded-md bg-white px-2 py-1`,
              ]}
              src={icon as string}
              alt="Renting agent"
              width={40}
              height={40}
            />
          ) : (
            icon
          )}
        </div>
      )}
      <div tw="w-full">
        <div tw="flex justify-between w-full gap-4">
          <Text tw="font-bold">{sender}</Text>
          <Text>{timeStamp}</Text>
        </div>

        {children}
      </div>
    </div>
  );
};
