import { localeEN, localeNL } from '@sal-solution/utils';
import React, { useState, useEffect, FC } from 'react';
import tw from 'twin.macro';
import { Text } from '../Text/Text';

const BannerContainer = tw.div`fixed bottom-0 [z-index: 9999999999] w-full bg-black text-white p-4 text-center lg:(flex items-center)`;
const Button = tw.button`mx-2 px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-700 focus:outline-none`;

type CookieConsentBannerProps = {
  locale?: typeof localeEN | typeof localeNL;
};

const locales = {
  [localeEN]: {
    message:
      'This website uses cookies to ensure you get the best experience on our website.',
    accept: 'Accept',
    decline: 'Decline',
  },
  [localeNL]: {
    message:
      'Deze website maakt gebruik van cookies om ervoor te zorgen dat u de beste ervaring op onze website krijgt.',
    accept: 'Accepteren',
    decline: 'Afwijzen',
  },
};

export const CookieConsentBanner: FC<CookieConsentBannerProps> = ({
  locale = localeNL,
}) => {
  const [showBanner, setShowBanner] = useState(false);

  const activeLocale = locales[locale];

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === null) {
      setShowBanner(true); // Show banner only if no decision has been recorded
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowBanner(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <BannerContainer>
      <Text>{activeLocale.message}</Text>
      <Button onClick={handleAccept}>{activeLocale.accept}</Button>
      <Button onClick={handleDecline}>{activeLocale.decline}</Button>
    </BannerContainer>
  );
};
