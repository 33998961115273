/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { FC } from 'react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
type CarouselProps = {
  images: string[];
};

export const Carousel: FC<CarouselProps> = ({ images, ...rest }) => {
  const moveRight = () => {
    const carousel = document.getElementById('carousel');
    if (carousel) {
      // if carousel is at the end, scroll to the beginning
      if (carousel.scrollLeft + carousel.clientWidth === carousel.scrollWidth) {
        carousel.scrollLeft = 0;
        return;
      }
      carousel.scrollLeft += 300;
    }
  };

  return (
    <div tw="relative lg:w-[max-content]">
      <div
        className="carousel carousel-center max-w-md p-4 space-x-4 bg-neutral rounded-box "
        id="carousel"
        {...rest}
      >
        {images.map((image, index) => (
          <div key={index} tw="carousel-item max-w-[300px] max-h-[400px]">
            <img alt="daisy" src={image} className="rounded-box object-cover" />
          </div>
        ))}
      </div>
      <button
        onClick={moveRight}
        tw="absolute top-1/2 -translate-y-1/2 -right-4 w-6 h-6 rounded-md bg-slate-200"
      >
        <ArrowRightIcon color="#000" />
      </button>
    </div>
  );
};
