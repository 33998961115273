/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PolymorphicComponentProps } from '@sal-solution/types';
import { ElementType, PropsWithChildren, ReactElement } from 'react';
import tw from 'twin.macro';

const TEXT_BASE = tw``;

export const TEXT_VARIANTS = {
  title: tw` font-bold text-2xl`,
  //
  subTitle: tw`font-bold text-xl`,
  //
  employeeTitle: tw`font-bold text-base`,
  //
  regular: tw`font-normal
  text-sm lg:text-base
  leading-[25px]`,
  sub: tw`
  text-gray-400`,
};

export const TEXT_SIZES = {
  regular: tw``,
  '2-lines': tw`overflow-hidden text-ellipsis [display: -webkit-box] [-webkit-line-clamp: 2] [line-clamp: 2] [-webkit-box-orient: vertical]`,
};

export type TextProps<C extends ElementType> = PolymorphicComponentProps<
  C,
  PropsWithChildren<{
    variant?: keyof typeof TEXT_VARIANTS;
    size?: keyof typeof TEXT_SIZES;
  }>
>;

export function Text<C extends ElementType = 'p'>({
  children,
  variant = 'regular',
  size = 'regular',
  as,
  ...rest
}: TextProps<C>): ReactElement {
  const Component = as ?? 'p';

  return (
    <Component
      css={[TEXT_BASE, TEXT_VARIANTS[variant], TEXT_SIZES[size]]}
      {...rest}
    >
      {children}
    </Component>
  );
}
