import { FC } from 'react';
import { Text } from '../Text/Text';

type TableProps = {
  head: (JSX.Element | string)[];
  rows: (JSX.Element | string)[][];
};

export const Table: FC<TableProps> = ({ head, rows }) => {
  return (
    <div className="overflow-x-auto w-full">
      <table className="table">
        {/* head */}
        <thead>
          <tr>
            {head.map((h, i) => (
              <Text as="th" key={i}>
                {h}
              </Text>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <tr className="hover" key={i}>
              {row.map((r, j) => (
                <Text as="td" key={j}>
                  {r}
                </Text>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
