'use client';
/** @jsxImportSource @emotion/react */
import { FC, useState, useRef, useEffect } from 'react';
import tw from 'twin.macro';
import { Text } from '../Text/Text';

type WriteTextProps = {
  texts: string[];
};

const writeSpeed = 100;
const pauseSpeed = 1000;

const colors = [tw`text-amber-400`, tw`text-blue-400`, tw`text-green-400`];

export const WriteTexts: FC<WriteTextProps> = ({ texts, ...rest }) => {
  const [color, setColor] = useState(colors[0]);
  const elementId = `text-${texts.join('-')}`;
  const currentIndexRef = useRef(0);

  const write = () => {
    const element = document?.getElementById(elementId);
    if (!element) return;

    const currentText = element.innerText;
    const index = currentIndexRef.current; //currentIndexOfText === -1 ? 0 : currentIndexOfText;
    setColor(colors[index] || colors[0]);
    const text = texts[index];
    const textLength = text.length;
    const currentTextLength = currentText.length;

    if (currentTextLength < textLength) {
      element.innerHTML = text.slice(0, currentTextLength + 1);
      setTimeout(write, writeSpeed);
    } else {
      setTimeout(() => {
        if (currentIndexRef.current === texts.length - 1)
          currentIndexRef.current = 0;
        else currentIndexRef.current++;
        unWrite();
      }, pauseSpeed);
    }
  };

  const unWrite = () => {
    const element = document?.getElementById(elementId);
    if (!element) return;

    const currentText = element.innerText;
    const currentTextLength = currentText.length;

    if (currentTextLength > 1) {
      element.innerHTML = currentText.slice(0, currentTextLength - 1);
      setTimeout(unWrite, writeSpeed);
    } else {
      setTimeout(() => {
        write();
      }, writeSpeed);
    }
  };

  useEffect(() => {
    if (document) write();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Text
      variant="title"
      id={elementId}
      tw="text-4xl"
      css={color}
      {...rest}
    ></Text>
  );
};
