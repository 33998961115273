/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PolymorphicComponentProps } from '@sal-solution/types';
import { ElementType, FC, PropsWithChildren, ReactElement } from 'react';
import tw from 'twin.macro';

const STYLE = tw`rounded-lg shadow-lg p-4`;

type CardProps<C extends ElementType> = PolymorphicComponentProps<
  C,
  PropsWithChildren
>;

export function Card<C extends ElementType>({
  children,
  as,
  ...props
}: CardProps<C>): ReactElement {
  const Component = as ?? 'div';
  return (
    <Component css={[STYLE]} {...props}>
      {children}
    </Component>
  );
}
