'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import React, { FC } from 'react';
import tw from 'twin.macro';

const styledBase = tw`animate-pulse border-2 border-opacity-10 bg-opacity-10`;

const styledShapes = {
  circle: tw`w-4 h-4 rounded-full border-red-500`,
  square: tw`w-4 h-4 border-blue-500`,
  dot: tw`rounded-full w-2 h-2 bg-green-500 border-none`,
} as const;

export type ShapesProps = {
  amount: number;
};

export const Shapes: FC<ShapesProps> = ({ amount = 20 }) => {
  const shapes = generateShapes(amount);
  return (
    <div tw="w-full h-full absolute overflow-hidden">
      {shapes.map((shape, index) => (
        <div key={index}>{shape}</div>
      ))}
    </div>
  );
};

const Shape: FC = () => {
  const { left, top } = randomDimensions();
  const shape = randomShape();
  return (
    <div
      css={[shape, styledBase]}
      style={{ position: 'absolute', left, top }}
    ></div>
  );
};

const randomDimensions = () => {
  if (typeof window === 'undefined' || !window) return { left: 0, top: 0 };
  const maxWidth = window.screen.width;
  const maxHeight = window.screen.height;

  const left = Math.floor(Math.random() * maxWidth);
  const top = Math.floor(Math.random() * maxHeight);

  return {
    left,
    top,
  };
};

const randomShape = () => {
  const shapes = Object.keys(styledShapes) as (keyof typeof styledShapes)[];
  const shape = shapes[Math.floor(Math.random() * shapes.length)];
  return styledShapes[shape];
};

const generateShapes = (amount: number) => {
  const shapes = [];
  for (let i = 0; i < amount; i++) {
    shapes.push(<Shape key={i} />);
  }
  return shapes;
};
