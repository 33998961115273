/** @jsxImportSource @emotion/react */
import tw, { TwStyle } from 'twin.macro';

import { ComponentProps, FC, PropsWithChildren } from 'react';
import {
  ArrowSVG,
  BottomCurveSVG,
  BottomWaveSVG,
  BottomWavesSVG,
  TopCurveSVG,
  SVGProps,
} from './SVG';
import { Section } from '../Layout/Layout';

export const bottomVariants = {
  Curved: BottomCurveSVG,
  Wave: BottomWaveSVG,
  Waves: BottomWavesSVG,
  Arrow: ArrowSVG,
};

export const topVariants = {
  Curved: TopCurveSVG,
  Wave: (props: SVGProps) => (
    <BottomWaveSVG
      tw="rotate-180 scale-[1.01] h-[calc(100% + 1px)]"
      {...props}
    />
  ),
  Waves: (props: SVGProps) => (
    <BottomWavesSVG tw="top-0 scale-[1.01]" {...props} />
  ),
  Arrow: (props: SVGProps) => (
    <ArrowSVG tw="top-0  scale-[1.01] rotate-180" {...props} />
  ),
};

export type HeroProps = {
  bottomVariant?: keyof typeof bottomVariants;
  topVariant?: keyof typeof topVariants;
  fill?: string;
  topFill?: string;
  bottomFill?: string;
  bgImage?: string;
  contentsStyle?: TwStyle[];
  innerStyle?: TwStyle[];
  topContentsStyle?: TwStyle[];
  bottomContentsStyle?: TwStyle[];
  bottomSVGProps?: ComponentProps<typeof BottomCurveSVG>;
};

const HeroStyle = tw.section`
w-full
min-h-[80vh]
h-full
bg-cover
bg-center
relative
text-black
flex
flex-col
items-center
justify-center
gap-y-0
overflow-hidden
`;

const Frame = tw.div` h-full grid lg:grid-cols-12 col-start-1 col-end-13`;
const Content = tw(Section)`z-10`;

export const Hero: FC<PropsWithChildren<HeroProps>> = ({
  bottomVariant,
  topVariant,
  fill = 'white',
  topFill,
  bottomFill,
  bgImage = 'https://www.independer-static.nl/assets/homepages/hero-images-2024/energie-desktop.webp',
  contentsStyle,
  bottomContentsStyle,
  topContentsStyle,
  children,
  bottomSVGProps,
  innerStyle,
  ...rest
}) => {
  const BottomSVG = bottomVariant ? bottomVariants[bottomVariant] : null;
  const TopSVG = topVariant ? topVariants[topVariant] : null;
  const bFill = bottomFill || fill;
  const tFill = topFill || fill;
  const bStyles = bottomContentsStyle || contentsStyle;
  const tStyles = topContentsStyle || contentsStyle;
  return (
    <HeroStyle
      {...rest}
      style={{
        backgroundImage: bgImage,
      }}
    >
      {TopSVG && <TopSVG fill={tFill} css={tStyles} />}
      <Frame css={contentsStyle}>
        <Content css={innerStyle} as="div">
          {children}
        </Content>
      </Frame>

      {BottomSVG && (
        <BottomSVG css={bStyles} fill={bFill} {...bottomSVGProps} />
      )}
    </HeroStyle>
  );
};

export const HeroSection = tw(Hero)`
grid items-start lg:grid-cols-12 lg:(col-start-1 col-end-13) 2xl:(col-start-1 col-end-13)
`;
