/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { FC } from 'react';

export type RadioProps = {
  label: string;
  name: string;
  required?: boolean;
  value?: string;
  className?: string;
};

export const Radio: FC<RadioProps> = ({
  label,
  name,
  required,
  value,
  className,
}) => {
  return (
    <div tw="form-control">
      <label tw="label cursor-pointer">
        <span tw="label-text">
          {label} {required && <span tw="text-red-500">*</span>}
        </span>
        <input
          type="radio"
          name={name}
          value={value}
          className={className}
          tw="radio checked:bg-red-500"
        />
      </label>
    </div>
  );
};
