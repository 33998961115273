'use client';
/** @jsxImportSource @emotion/react */

import React, { FC, PropsWithChildren } from 'react';
import tw from 'twin.macro';

const STATUSES = {
  success: tw`bg-green-500 text-white`,
  error: tw`bg-red-500 text-white`,
  warning: tw`bg-yellow-500 text-white`,
};

const Bar = tw.div`rounded w-full p-2`;

export type StatusBarProps = {
  status?: keyof typeof STATUSES;
};

export const StatusBar: FC<PropsWithChildren<StatusBarProps>> = ({
  status = 'success',
  children,
  ...rest
}) => {
  const statusStyle = STATUSES[status];
  return (
    <Bar css={[statusStyle]} {...rest}>
      {children}
    </Bar>
  );
};
