/** @jsxImportSource @emotion/react */
import { FC } from 'react';

export type StepperProps = {
  steps: string[];
  currentStep: number;
};

export const Stepper: FC<StepperProps> = ({ steps, currentStep, ...rest }) => {
  return (
    <ul {...rest} className="steps w-full">
      {steps.map((step, index) => (
        <li
          key={index}
          className={`step text-sm ${
            index + 1 <= currentStep ? 'step-primary' : ''
          }`}
        >
          {step}
        </li>
      ))}
    </ul>
  );
};
