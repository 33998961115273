'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import React, { FC } from 'react';
import { Shapes } from '../Shapes/Shapes';
import tw from 'twin.macro';

const Layout = tw.div`flex relative w-full h-full bg-orange-100  max-w-screen-md mx-auto`;
const Content = tw.div`w-full flex items-end z-10 h-screen`;
const ContentTop = tw.div`flex justify-center items-center w-full h-[40vh] px-4 absolute z-10 top-0`;
const ContentBottom = tw.div`flex justify-center items-center w-full min-h-[70vh] relative top-[30vh] rounded-tl-3xl rounded-tr-3xl bg-white px-4 mt-auto`;

export type ContentAndImageLayoutProps = {
  topContent: React.ReactNode;
  bottomContent: React.ReactNode;
};

export const ContentAndImageLayout: FC<ContentAndImageLayoutProps> = ({
  topContent,
  bottomContent,
}) => {
  return (
    <Layout>
      <Shapes amount={100} />
      <Content>
        <ContentTop>{topContent}</ContentTop>
        <ContentBottom>{bottomContent}</ContentBottom>
      </Content>
    </Layout>
  );
};
